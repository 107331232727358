import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Heading from '../Heading';
import TitleImage from './images/title.gif';
import AvatarImage from './images/pic.gif';
import styles from './styles.module.scss';

function Sidebar({ children }) {
  return (
    <div className={styles.sidebar}>
      <Link to="/" className={styles.titles}>
        <img src={TitleImage} className={styles.titleImage} alt="Milk Love" />
        <p className={styles.subtitle}>Everybody loves milk, do you?</p>
      </Link>

      <Heading title="About me" />

      <div className={styles.bio}>
        <img
          src={AvatarImage}
          className={styles.avatarImage}
          alt="Mr Milk Love"
        />

        <dl>
          <dt>Name:</dt>
          <dd>Mr Milk Love</dd>
          <dt>Location:</dt>
          <dd>United Kingdom</dd>
        </dl>
      </div>

      {children}
    </div>
  );
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sidebar;
