import React from 'react';
import PropTypes from 'prop-types';
import BGImage from './images/background.gif';
import styles from './styles.module.scss';

function Main({ children }) {
  return (
    <div className={styles.main}>
      <img src={BGImage} className={styles.BGImage} alt="" />
      <div className={styles.content}>{children}</div>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
