import React from 'react';
import { Link } from 'gatsby';
import HeroImage from './images/header.gif';
import styles from './styles.module.scss';

function Hero() {
  return (
    <Link to="/">
      <img src={HeroImage} className={styles.hero} alt="Milk Love" />
    </Link>
  );
}

export default Hero;
