import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, Link, graphql } from 'gatsby';
import SEO from '../seo';
import Hero from '../Hero';
import Main from '../Main';
import Sidebar from '../Sidebar';
import Heading from '../Heading';
import styles from './styles.module.scss';

function Page({ children, title }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
            edges {
              node {
                html
                fields {
                  slug
                }
                frontmatter {
                  date(formatString: "MMMM YYYY---YYYY/MM")
                  title
                }
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allMarkdownRemark.edges;

        const postDates = posts.reduce((acc, cur) => {
          const {
            frontmatter: { date },
          } = cur.node;

          const accDateGroupIndex = acc.findIndex(
            group => group.dateGroup === date
          );

          let addDateGroup;
          if (accDateGroupIndex === -1) {
            const [dateText, dateSlug] = date.split('---');
            addDateGroup = {
              dateGroup: date,
              dateText,
              dateSlug,
            };
          }

          return !!addDateGroup ? [...acc, addDateGroup] : acc;
        }, []);

        return (
          <div className={styles.page}>
            <SEO title={title} />

            <Hero />

            <div className={styles.content}>
              <div className={styles.sidebar}>
                <Sidebar>
                  <Heading title="Previous Posts" />

                  <ul className={styles.recentPosts}>
                    {posts.map(({ node }) => {
                      const title = node.frontmatter.title || node.fields.slug;

                      return (
                        <li key={node.fields.slug}>
                          <Link to={`/blog${node.fields.slug}`}>{title}</Link>
                        </li>
                      );
                    })}
                  </ul>

                  <Heading title="Archives" />

                  <ul className={styles.recentPosts}>
                    {postDates.map(({ dateText, dateSlug }) => {
                      return (
                        <li key={dateSlug}>
                          <Link to={`/blog/${dateSlug}`}>{dateText}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </Sidebar>
              </div>

              <div className={styles.main}>
                <Main>{children}</Main>

                <p className={styles.footer}>
                  Copyright © Mr Milk Love. All Rights Reserved. Designed and
                  developed by Mr Milk Love
                </p>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

Page.defaultProps = {
  title: null,
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
